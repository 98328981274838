@import 'src/core/common/assets/styles/mixins';

.root {
  strong {
    font-weight: 700;
  }
}

.mobile {
  @include hideOnDesktop();

  line-height: 1.2;
}

.mobileDescription {
  font-size: 12px;
}

.desktop {
  @include hideOnMobile();
}