@import "src/core/common/assets/styles/mixins";

.content {
  @include pageContainerMaxWidth();
  @include sectionSidePaddings();

  display: flex;
  flex-direction: column;

  @include respondTo(l) {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.title {
  font-weight: 700;
  font-size: 28px;
  line-height: 33px;
  text-align: center;
  color: var(--typography-100);
  margin-bottom: 40px;

  @include respondTo(l) {
    font-size: 40px;
    line-height: 145%;
    margin-bottom: 50px;
  }
}

.description {
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  width: 100%;
  color: var(--typography-100);
  font-size: 20px;
  font-weight: 700;
  line-height: 145%;
  text-align: left;
  margin-bottom: 28px;
  
  @include respondTo(l) {
    font-size: 28px;
    margin-bottom: 32px;
  }
}

.faqListWrapper {
  width: 100%;
}

.faqList {
  margin-bottom: -30px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;

  @include respondTo(l) {
    margin-bottom: -40px;
  }
}
