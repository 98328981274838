@import "src/core/common/assets/styles/mixins";

.root {
  padding: 10px 14px;
  background-color: var(--primary-300);
  position: fixed;
  bottom: 7px;
  right: 8px;
  color: var(--typography-900);
  border-radius: 30px;
  font-size: 14px;
  font-weight: 600;
  line-height: 135%;
  display: flex;
  align-items: center;
  z-index: $fixedSupportButtonZIndex;


  @include respondTo(l) {
    padding: 10px 23px;
    bottom: 20px;
    right: 45px;
  }
}

.icon {
  margin-right: 4px;
  fill: var(--typography-900);
  width: 20px;
  height: 20px;
}
