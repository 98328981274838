@import "src/core/common/assets/styles/mixins";

.root {
  padding: 8px 12px 8px 0;

  @include respondTo(l) {
    padding: 0;
  }
}

.linkInner {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 32px;
  background-color: var(--primary-100);
  border-radius: 4px;

  @include respondTo(l) {
    width: 32px;
    height: 24px;
  }
}

.linkLogo {
  fill: var(--typography-900);
}

@include whenThemeIs(light) {
  .linkLogo {
    fill: var(--typography-100);
  }

  .linkInner {
    background-color: var(--primary-900);
  }
}

.instagramLogo {
  width: 20px;
  height: 20px;

  @include respondTo(l) {
    height: 16px;
    width: 16px;
  }
}

.tiktokLogo {
  width: 24px;
  height: 24px;

  @include respondTo(l) {
    height: 18px;
    width: 18px;
  }
}

.facebookLogo {
  width: 9px;
  height: 18px;

  @include respondTo(l) {
    height: 14px;
    width: 7px;
  }
}

.threadsLogo {
  width: 24px;
  height: 24px;
}

.twitterLogo {
  width: 16px;
  height: 16px;
}

.linkedinLogo {
  width: 24px;
  height: 24px;
}