@import "src/core/common/assets/styles/mixins";

$navActionsZIndex: 1;
$navLinksZIndex: 2;

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: $stickyHeaderZIndex;
  background-color: var(--primary-page-background);
}

.logo {
  fill: var(--primary-100);
}

.logoMobile {
  @include hideOnDesktop();

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .logo {
    width: 16px;
    height: 16px;
  }
}

.logoDesktop {
  @include hideOnMobile();

  .logo {
    @include respondTo(l) {
      width: 140px;
      height: 20px;
    }
  }
}

.headerMenu {
  @include hideOnDesktop();
}

.nav {
  @include pageContainerMaxWidth(1486px);

  width: 100%;
  padding: 12px 15px;
  flex-shrink: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;

  @include respondTo(l) {
    padding: 26px 100px 22px;
  }
}

.navActionsList {
  display: flex;
  z-index: $navActionsZIndex;

  @include respondTo(l) {
    flex-shrink: 0;
  }
}

.navActionItem {
  margin-right: 46px;
  display: flex;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }
}

.switchActionItem {
  @include hideOnMobile();
}

.navLinkList {
  display: none;

  @include respondTo(l) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -10px;
    margin-left: 30px;
    margin-right: 30px;
    z-index: $navLinksZIndex;
  }
}

.navAction {
  font-size: 16px;
  line-height: 135%;
  color: var(--primary-300);
  display: inline-flex;
  align-items: center;
}

.navActionIcon {
  width: 18px;
  height: 18px;
  fill: currentColor;
  margin-left: 4px;
}
