@import "src/core/common/assets/styles/mixins";

.root {
  background-color: var(--secondary-900);
}

.content {
  @include pageContainerMaxWidth();
  @include sectionSidePaddings();

  padding-top: 20px;
  padding-bottom: 12px;

  @include respondTo(l) {
    padding: 48px 100px 24px;
  }
}

.linkList {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: -8px;
  padding-bottom: 4px;

  @include respondTo(l) {
    margin-left: -20px;
    margin-right: -20px;
    flex-wrap: nowrap;
    padding-bottom: 36px;
  }
}

.linkGroup {
  flex: 0 0 auto;
  max-width: 50%;
  width: 100%;
  padding-right: 16px;
  padding-left: 16px;
  padding-bottom: 20px;

  @include respondTo(l) {
    max-width: 190px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.socialsLinkGroup {
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-flow: row wrap;

  @include respondTo(l) {
    flex-direction: column;
    max-width: 235px;
    margin-right: 15px;
  }
}

.ownLinksGroup {
  flex: 1;
}

.ownLinksGroupList {
  display: flex;
  flex-wrap: wrap;
}

.mainLogo {
  fill: var(--primary-100);
  margin-bottom: 12px;
  width: 140px;
  height: 20px;
}

.mainLink {
  display: flex;
  align-items: center;
}

.divider {
  display: none;

  @include respondTo(l) {
    display: inline-block;
    width: 100%;
    max-width: 140px;
    height: 1px;
    background-color: var(--secondary-800);
    margin-bottom: 16px;
  }
}

.socialsList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-left: 0;
  margin-right: 0;

  @include respondTo(l) {
    margin-bottom: 34px;
    margin-left: -4px;
    margin-right: -4px;
    width: auto;
  }
}

.socialItem {
  display: flex;
  padding-left: 2px;
  padding-right: 2px;

  @include respondTo(l) {
    padding-left: 4px;
    padding-right: 4px;
  }
}

.socialLinkInner {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 32px;
  background-color: var(--primary-100);
  border-radius: 4px;

  @include respondTo(l) {
    width: 32px;
    height: 24px;
  }
}

.socialLogo {
  color: var(--typography-900);
}

@include whenThemeIs(light) {
  .socialLogo {
    color: var(--typography-100);
  }

  .socialLinkInner {
    background-color: var(--primary-900);
  }
}

.instagramLogo {
  width: 20px;
  height: 20px;

  @include respondTo(l) {
    height: 16px;
    width: 16px;
  }
}

.tiktokLogo {
  width: 24px;
  height: 24px;

  @include respondTo(l) {
    height: 18px;
    width: 18px;
  }
}

.facebookLogo {
  width: 9px;
  height: 18px;

  @include respondTo(l) {
    height: 14px;
    width: 7px;
  }
}

.threadsLogo {
  width: 24px;
  height: 24px;
}

.twitterLogo {
  width: 16px;
  height: 16px;
}

.downloadLinksList {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-top: 12px;

  @include respondTo(l) {
    flex-direction: column;
    margin-top: 0;
    width: auto;
  }
}

.downloadLinkItem {
  margin-bottom: 16px;
  margin-right: 30px;

  &:last-child {
    margin-bottom: 0;
    margin-right: 0;
  }

  @include respondTo(l) {
    margin-right: 0;
  }
}

.downloadLinkImage {
  @include positionFutureImage(135px)
}

.linkGroupTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;
  margin-bottom: 16px;
  color: var(--typography-100);

  @include respondTo(l) {
    font-size: 18px;
    margin-bottom: 20px;
  }
}

.linkListItem {
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }

  @include respondTo(l) {
    margin-bottom: 16px;
  }
}

.link {
  display: inline-block;
  min-width: 120px;
  font-size: 12px;
  line-height: 145%;
  color: var(--typography-200);

  @include respondTo(l) {
    font-size: 14px;
    min-width: 190px;
  }
}

.button {
  display: inline-block;
  font-size: 12px;
  line-height: 145%;
  color: var(--typography-200);

  @include respondTo(l) {
    font-size: 14px;
  }
}

.underline {
  border: 0.5px solid var(--secondary-800);
  margin-bottom: 12px;

  @include respondTo(l) {
    margin-bottom: 20px;
  }
}

.copyright {
  color: var(--typography-600);
  font-size: 12px;
  line-height: 145%;
  display: flex;
  justify-content: center;

  @include respondTo(l) {
    font-size: 14px;
  }
}

.paymentMethods {
  margin-bottom: 4px;

  @include respondTo(l) {
    margin-bottom: 8px;
  }
}