@import 'src/core/common/assets/styles/mixins';

.menuControl, .menuClose {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.menuControlIcon {
  fill: var(--typography-100);
  width: 24px;
  height: 24px;
}

.root {
  font-size: 0;
  line-height: 0;
  position: relative;
}

.menuContent {
  color: var(--typography-100);
  height: 100%;
}

.menuContentWrapper {
  width: 100%;
  max-width: 300px;
  background-color: var(--primary-900) !important;
  padding: 97px 20px 30px;
}

.menuCloseIcon {
  fill: var(--primary-600);
  width: 24px;
  height: 24px;
}

.menuCloseWrapper {
  position: absolute;
  top: 36px;
  right: 23px;
}

.menuNavigation {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.menuNavLinkList {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.menuNavLinkItem {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 40px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--primary-800);

  &:last-child {
    margin-bottom: 0;
  }
}

.menuNavLink {
  display: inline-block;
  width: 100%;
}

.menuNavActionsList {
  margin-top: auto;
}

.menuNavAction {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: var(--typography-200);
  display: flex;
  justify-content: space-between;
}
