@import "src/core/common/assets/styles/mixins";

.faqListItem {
  padding-bottom: 30px;

  @include respondTo(l) {
    padding-bottom: 40px;
  }
}

.faq {
  border-bottom: 1px solid var(--secondary);
}

.faqHeader {
  min-height: initial !important;
  padding-bottom: 10px !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  div {
    background-color: transparent;
    box-shadow: none;
  }

  @include respondTo(l) {
    padding-bottom: 14px !important;
  }
}

.faqHeaderIcon {
  fill: var(--secondary);
  width: 16px;
  height: 16px;
}

.faqTitle {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  color: var(--typography-100);

  @include respondTo(l) {
    font-size: 18px;
    line-height: 24px;
  }
}

.faqContent {
  @include contentManagerTextReset();

  display: flex;
  flex-direction: column;
  padding: 0 0 10px !important;
  color: var(--typography-100);
  background-color: transparent !important;
  box-shadow: none !important;

  p, ul, ol {
    line-height: 24px;
    font-size: 12px;

    @include respondTo(l) {
      font-size: 14px;
    }
  }
}
