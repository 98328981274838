.root {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--primary-800);
  display: flex;
  width: 100%;
  min-height: 46px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.menuNavLink {
  width: 100%;
}

.openButton {
  flex: 1;
  text-align: left;
  display: flex;
  align-items: center;
  font-weight: 600;
}

.openArrow {
  transform: rotate(-90deg);
  margin-left: auto;
  display: inline-block;
  width: 16px;
  height: 10px;
  fill: var(--primary-600);
}

.submenu {
  position: absolute;
  background-color: var(--primary-900);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 16px;
  line-height: 23px;
}

.submenuClosed {
  display: none;
}

.submenuOpened {
  display: block;
}

.submenuTitle {
  display: flex;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--primary-800);
  text-align: center;
}

.submenuTitleInner {
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.closeArrow {
  transform: rotate(90deg);
  width: 16px;
  height: 10px;
  fill: var(--primary-600);
}

.closeButton {
  align-self: center;
}

.submenuList {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
}

.submenuListItem {
  margin-bottom: 4px;
  padding-bottom: 8px;
  padding-top: 8px;
  display: flex;

  &:last-child {
    margin-bottom: 0;
  }
}

.submenuLink {
  flex: 1;
  font-weight: 600;
}

