@import "src/core/common/assets/styles/mixins";

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.logo {
  width: 23px;
  height: 16px;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 4px;

  @include respondTo(l) {
    width: 35px;
    height: 24px;
  }
}