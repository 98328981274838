@import 'src/core/common/assets/styles/mixins';

.root {
  --primary: var(--secondary-700);
  --secondary: var(--star);

  padding: 8px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--secondary);
  margin-bottom: 10px;

  .icon {
    fill: var(--primary);
    color: var(--primary);
    width: 24px;
    height: 24px;
    margin-right: 8px;

    @include respondTo(l) {
      width: 28px;
      height: 28px;
    }
  }

  .text {
    color: var(--primary);
    font-size: 14px;
    margin-right: 8px;

    @include respondTo(l) {
      font-size: 18px;
      margin-right: 32px;
    }
  }

  .link {
    background-color: var(--primary);
    color: var(--secondary);
    padding: 10px 22px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 12px;

    @include respondTo(l) {
      font-size: 14px;
      padding: 13px 14px;
    }
  }

  @include respondTo(l) {
    justify-content: center;
    margin-bottom: 0;
  }
}

@include whenThemeIs(light) {
  .root {
    --primary: var(--star);
    --secondary: #24244F;
  }
}
