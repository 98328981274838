.root {
  margin-right: 35px;
  padding-left: 4px;
  padding-right: 4px;
  font-size: 16px;
  line-height: 24px;
  color: var(--typography-100);
  margin-bottom: 10px;

  &:last-child {
    margin-right: 0;
  }
}

.menu {
  padding-top: 16px;
  padding-bottom: 16px;
  min-width: 270px;
  box-shadow: 2px 4px 12px 0 rgba(28, 28, 62, 0.30);
  background-color: var(--primary-800);
  border-radius: 8px;
  flex-direction: column;
  display: flex;
}

.trigger {
  color: var(--typography-100);
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  padding-bottom: 15px;
}

.itemTrigger {
  padding: 8px 16px;
  flex: 1;
  min-height: 46px;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  font-size: 16px;
  width: 100%;

  &:hover {
    background-color: var(--primary-700);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -8px;
    width: 10px;
    height: 46px;
    z-index: 1;
  }
}

.triggerArrow {
  transform: rotate(-90deg);
  fill: var(--primary);
  margin-left: auto;
}